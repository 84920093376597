import conect from './api'

const { axiosIns } = conect()

export default {
  async getUsersReferralList(params = {}) {
    const { error, result } = (await axiosIns.get('/users-referral', { params })).data
    if (error) throw error

    return result
  },
}
