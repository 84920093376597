import usersReferralRepository from '@/repository/usersReferralRepository'
import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import ability from '@/libs/acl/ability'

export default function getUsersReferralList() {
  const Columns = [
    { key: 'id', label: 'ИД', sortable: true },
    { key: 'user', label: 'Пользователь' },
    { key: 'active', label: 'Статус аккаунта' },
    { key: 'email', label: 'E-mail' },
    { key: 'status', label: 'Статус' },
    { key: 'createdAt', label: 'Реферал создан', sortable: true },
    { key: 'lastLogin', label: 'Последний вход', sortable: true },
  ]

  const refProfileReferrals = ref(null)
  const perPage = ref(10)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const isLoading = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refProfileReferrals.value ? refProfileReferrals.value.localItems.length : 0

    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItems.value,
    }
  })

  if (!ability.can('read', 'UserManage')) {
    isLoading.value = false
    store.commit('app/SET_ERROR', 'Недостаточно прав для чтения')
  }

  const refetchData = () => {
    refProfileReferrals.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const fetchItems = (ctx, callback) => {
    const userId = ref(parseInt(router.currentRoute.params.id, 10))
    usersReferralRepository.getUsersReferralList({
      query: searchQuery.value,
      page: currentPage.value,
      limit: perPage.value,
      sord: isSortDirDesc.value ? 'desc' : 'asc',
      sidx: sortBy.value,
      userId: userId.value,
    })
      .then(response => {
        const { items, count } = response
        if ((items || []).length === 0) {
          callback([])
        }

        const referralsList = items.map(x => {
          const {
            referral, createdAt, updatedAt, status,
          } = x
          return {
            createdAt, updatedAt, status, user: `${referral.firstName || ''} ${referral.lastName || ''}`, ...referral,
          }
        })

        callback(referralsList)
        totalItems.value = count
        isLoading.value = false
      })
      .catch(error => {
        isLoading.value = false
        store.commit('app/SET_ERROR', error)
      })
  }

  const clearSelectTable = () => {
    refProfileReferrals.value.clearSelected()
  }
  return {
    fetchItems,
    perPage,
    currentPage,
    totalItems,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refProfileReferrals,
    Columns,
    clearSelectTable,
    refetchData,

    isLoading,
  }
}
